import { LegacyWebappResponse } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class LoginAsUser<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;
	traceDetails: string | undefined;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
		this.traceDetails = process.env.TRACE_DETAILS?.trim();
	}

	/**
	 * @description This API used to get login sales rep or CSR user authToken
	 *
	 * @tags Login as user
	 * @name CsrfAuthToken
	 * @summary This API used to get login sales rep or CSR user authToken
	 * @request GET:/GetCSRFAuthTokenV2
	 * @secure
	 * @response `200` `LegacyWebappResponse` OK
	 * @response `201` `void` The requested resource has been created.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	csrfAuthToken = (
		query: {
			/** The store identifier. */
			storeId: string;
			/** Input request type */
			requesttype: string;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (loggerCan('trace') && (!this.traceDetails || this.traceDetails.includes('csrfAuthToken'))) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: null ?? {},
				methodName: 'csrfAuthToken',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<LegacyWebappResponse, void>({
			path: `/GetCSRFAuthTokenV2`,
			method: 'GET',
			query: query,
			secure: true,
			...params,
		});
	};
	/**
	 * @description This API used to set the onBehalf user in session
	 *
	 * @tags Login as user
	 * @name AjaxRunAsUserSetInSession
	 * @summary This API used to set the onBehalf user in session
	 * @request POST:/AjaxRunAsUserSetInSession
	 * @secure
	 * @response `200` `LegacyWebappResponse` OK
	 * @response `201` `void` The requested resource has been created.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	ajaxRunAsUserSetInSession = (
		query: {
			/** The store identifier. */
			storeId: string;
			/** Input request type */
			requesttype: string;
			/** Input runAsUserId that you want to set in sesion. Example customer search section selected userId */
			runAsUserId: string;
			/** Input captured authToken from previous /GetCSRFAuthTokenV2 API */
			authToken: string;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('ajaxRunAsUserSetInSession'))
		) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: null ?? {},
				methodName: 'ajaxRunAsUserSetInSession',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<LegacyWebappResponse, void>({
			path: `/AjaxRunAsUserSetInSession`,
			method: 'POST',
			query: query,
			secure: true,
			...params,
		});
	};
	/**
	 * @description This API used to restore user in session
	 *
	 * @tags Login as user
	 * @name AjaxRestoreOriginalUserSetInSession
	 * @summary This API used to restore user in session
	 * @request POST:/AjaxRestoreOriginalUserSetInSession
	 * @secure
	 * @response `200` `LegacyWebappResponse` OK
	 * @response `201` `void` The requested resource has been created.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	ajaxRestoreOriginalUserSetInSession = (
		query: {
			/** The store identifier. */
			storeId: string;
			/** Input request type */
			requesttype: string;
			/** Input runAsUserId that you want to set in sesion. Example customer search section selected userId */
			runAsUserId: string;
			/** Input captured authToken from previous /GetCSRFAuthTokenV2 API */
			authToken: string;
			/** Input catalog Id. */
			catalogId?: number;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('ajaxRestoreOriginalUserSetInSession'))
		) {
			const paramsLogger = logger.child({
				params,
				query: query ?? {},
				body: null ?? {},
				methodName: 'ajaxRestoreOriginalUserSetInSession',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<LegacyWebappResponse, void>({
			path: `/AjaxRestoreOriginalUserSetInSession`,
			method: 'POST',
			query: query,
			secure: true,
			...params,
		});
	};
}
